import React, { useEffect, useRef } from "react"
import { Fade } from "react-awesome-reveal"
import lottie from "lottie-web"
import animationData from "../json/lottie-hero.json"
import ButtonPrimary from "./button/buttonPrimary"

const Hero = ({ data }) => {
  const lottieRef = useRef(null)

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animationData,
    })

    return () => {
      lottieAnimation.destroy()
    }
  }, [])

  return (
    <div className="lg:h-y grid grid-cols-12 items-center gap-x-gs gap-y-14 px-ogs pb-header-height">
      <div className="row-start-2 lg:row-start-1 col-span-12 lg:col-span-5">
        <Fade fraction={0.5} triggerOnce>
          <div className="md:max-w-md">
            {data.home_hero_heading && (
              <h1 className="text-xl lg:text-3xl xl:text-4xl 2xl:text-6xl font-extralight uppercase tracking-tight-5 leading-none mb-7">
                {data.home_hero_heading}
              </h1>
            )}
            {data.home_hero_paragraph && (
              <p className="leading-relaxed mb-8">{data.home_hero_paragraph}</p>
            )}
            <ButtonPrimary small label="Learn More">
              Learn More
            </ButtonPrimary>
          </div>
        </Fade>
      </div>
      <div className="row-start-1 lg:row-start-1 col-span-12 lg:col-span-7 flex items-center justify-center">
        <div className="-mr-ogs" ref={lottieRef}></div>
      </div>
    </div>
  )
}

export default Hero
