import React from "react"

const FeaturesCompactB = () => {
  return (
    <svg viewBox="0 0 228 161" fill="none">
      <linearGradient id="a">
        <stop offset="0" stopColor="#4a8d36" />
        <stop offset=".9942" stopColor="#8ce3d9" />
        <stop offset="1" stopColor="#a5dbe5" />
      </linearGradient>
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        x1="106.7"
        x2="131.902"
        xlinkHref="#a"
        y1="59.5316"
        y2="59.5316"
      />
      <linearGradient id="c">
        <stop offset="0" stopColor="#4a8d36" />
        <stop offset=".969502" stopColor="#a5dbe5" />
      </linearGradient>
      <linearGradient
        id="d"
        gradientUnits="userSpaceOnUse"
        x1="122.414"
        x2="167.968"
        xlinkHref="#c"
        y1="122.009"
        y2="87.2402"
      />
      <linearGradient
        id="e"
        gradientUnits="userSpaceOnUse"
        x1="92.4959"
        x2="123.046"
        xlinkHref="#a"
        y1="76.8321"
        y2="76.8321"
      />
      <linearGradient
        id="f"
        gradientUnits="userSpaceOnUse"
        x1="108.643"
        x2="125.578"
        xlinkHref="#c"
        y1="72.1374"
        y2="63.1855"
      />
      <linearGradient
        id="g"
        gradientUnits="userSpaceOnUse"
        x1="189.94"
        x2="226.325"
        xlinkHref="#c"
        y1="45.0376"
        y2="6.41651"
      />
      <linearGradient
        id="h"
        gradientUnits="userSpaceOnUse"
        x1="222.08"
        x2="227.739"
        xlinkHref="#c"
        y1="66.0376"
        y2="60.0299"
      />
      <path d="m115.4 16.5376h-39.1998v27.9h39.1998z" fill="#fff" />
      <path
        d="m176.5 127.038c-30.8 1.7-34.8-13-34.8-13l-20.6-4.7c-4.7-1.1-8-5.2-8-10.1004 0-1.9 1.6-3.4 3.5-3.3 9.2.6 34.5 2 46.5 2.4 14.8.4 27.6 11.7004 27.6 11.7004l37-8.8v46.5l-32.9-1.2-48.7 13.5-28.7-3-18.2999-7.3 3.1999-8 19.4 5 17.8-.8z"
        fill="#6295d2"
      />
      <path
        d="m145.7 113.938s.2 11.099-23.3 14h-18.4s-27.8-5.001-30.4 1.999c0 0-6.8.1-6.5 7.6l10.1 3.4s2.3 4.3 12.1 6.5 12.3 3.201 12.3 3.201 65.2-2.101 65.2-2.601 18.7-14.5 18.7-14.5l2.8-9-22.8-4.199z"
        fill="#6295d2"
      />
      <path
        d="m119.3 72.1375c6.959 0 12.6-5.6412 12.6-12.6s-5.641-12.6-12.6-12.6-12.6 5.6412-12.6 12.6 5.641 12.6 12.6 12.6z"
        stroke="url(#b)"
        strokeMiterlimit="10"
      />
      <path
        d="m156.8 101.838-14.6-32.8004c-1.9-4.3-6.8-6.4-11.2-4.8l-9.2 3.4c-4.4 1.6-6.8 6.4-5.5 10.9l9.9 34.5004c2 6.9 9.4 10.7 16.1 8.2l7.5-2.7c6.7-2.5 9.9-10.2 7-16.7zm-21.6-32.3004-13.2 4.9c-.7.2-1.4-.2-1.5-.9-.1-1.3.7-2.6 2-3.1l9.7-3.5c1.3-.5 2.7 0 3.5 1.1.4.5.2 1.3-.5 1.5z"
        fill="url(#d)"
      />
      <path
        d="m105.1 91.5911-4.8-3.3-4.8-3.4-5.1 7.2.2 2.1-3.8 5.3.7 3.2999-2.9.9.7 3.2-.1.2-3.5.8.2 3.2-2.8.5-2.1 3 1 5.5 5.5-1 2.1-3-.5-2.8 3.1-.8-.4-3.7.1-.1 3.2-.4-.1-3.1 3.3-.5 3.7-5.2999 2-.6z"
        fill="#6295d2"
      />
      <path
        d="m117.8 63.1911c-4.9-3.4-11.5-2.8-15.6 1.5l-7.2002 7.4c-3.8 3.9-3.2 10.3 1.3 13.4l8.0002 5.6c4.5 3.1 10.7 1.5 13.1-3.4l4.5-9.3c2.5-5.4.8-11.8-4.1-15.2zm-1.4 10.6-8.1-5.6c-1.4-1-1.1-3.1.5-3.7.7-.2 1.4-.1 2 .2l8 5.6c.6.4.9 1.1.9 1.7 0 1.8-1.9 2.8-3.3 1.8z"
        fill="url(#e)"
      />
      <path
        d="m119.3 72.1374c-.4 0-.8 0-1.2-.1-6.4-.6-11.4-6-11.4-12.6 0-7 5.6-12.6 12.6-12.6"
        stroke="url(#f)"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="m0 46.9375 71.3-43.9c4.1-2.7 9.1-3.6 14-2.7l26.5 5.2c4.6.9 8.2 4.3 9.5 8.8l8.8 31.7c.9 3.3-1.6 6.5-5 6.4-1.8-.1-3.4-1.1-4.3-2.7l-14.9-28.1-13 2.4002c-3.5 0-7.3 4-7.6 7.5v8.4999l-53.7 35.3999-31.6 38.5995z"
        fill="#a5dbe5"
      />
      <path
        d="m31.6001 75.4376s16 6.2 27.4-3.6 24.8-16.9 34.9-17.3c6.7999-.2 17.8999-.1 24.3999-.1 3 0 5.6-2.2 5.9-5.2.3-2.9-1.5-5.6-4.3-6.3l-39.3999-10.4z"
        fill="#a5dbe5"
      />
      <path
        d="m228 19.022h-18.984v-18.9844023h-6.891v18.9844023h-19.125v7.0312h19.125v18.9844h6.891v-18.9844h18.984z"
        fill="url(#g)"
      />
      <path
        d="m179 35.3188h-8.859v-9.2812h-3.216v9.2812h-8.925v3.4375h8.925v9.2813h3.216v-9.2813h8.859z"
        fill="#a5dbe5"
      />
      <path d="m221 59.0376h7v7h-7z" fill="url(#h)" />
    </svg>
  )
}

export default FeaturesCompactB
