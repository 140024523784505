import React from "react"
import { Fade } from "react-awesome-reveal"
import ContactForm from "./contactForm"

const Contact = ({ data }) => {
  return (
    <Fade fraction={0.5} triggerOnce>
      <div className="bg-blue-grey px-ogs py-12 md:py-32 lg:py-36">
        {data.home_form_heading && data.home_form_sub_heading && (
          <div className="text-center mb-8 md:mb-12 lg:mb-16">
            {data.home_form_heading && (
              <h3 className="text-xl md:text-3xl lg:text-4xl uppercase font-extralight leading-none mb-7 md:mb-12 lg:mb-16 tracking-tight-5">
                {data.home_form_heading}
              </h3>
            )}
            {data.home_form_sub_heading && (
              <div className="flex items-center justify-center space-x-6 lg:space-x-8">
                <span className="hidden md:block w-7 h-7 lg:w-9 lg:h-9">
                  <svg
                    className="w-full h-full fill-current"
                    viewBox="0 0 37 11"
                  >
                    <path d="M36.1067 5.88717C36.3904 5.6035 36.3904 5.14357 36.1067 4.8599L31.484 0.23719C31.2003 -0.0464821 30.7404 -0.0464821 30.4567 0.23719C30.173 0.520863 30.173 0.980787 30.4567 1.26446L34.5658 5.37354L30.4567 9.48261C30.173 9.76628 30.173 10.2262 30.4567 10.5099C30.7404 10.7936 31.2003 10.7936 31.484 10.5099L36.1067 5.88717ZM0 6.09992H35.5931V4.64715H0V6.09992Z" />
                  </svg>
                </span>
                <p className="text-base md:text-md lg:text-lg font-light">
                  {data.home_form_sub_heading}
                  {/*<span className="relative">Register</span> now and increase
                  employee conversion with Remarketing+*/}
                </p>
              </div>
            )}
          </div>
        )}
        <div className="grid grid-cols-12 gap-x-gs">
          <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <ContactForm />
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Contact
