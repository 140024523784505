import React from "react"
import { Fade } from "react-awesome-reveal"

import VectorFeatures from "./svg/features"
import VectorFeaturesCompactA from "./svg/features-compact-a"
import VectorFeaturesCompactB from "./svg/features-compact-b"
import VectorPlus from "./svg/plus"

const Features = ({ data }) => {
  return (
    <div className="px-ogs ss-top-large ss-bottom-small">
      {data.home_primary_heading && (
        <Fade fraction={0.5} triggerOnce>
          <h2 className="text-xl lg:text-3xl xl:text-4xl 2xl:text-6xl text-green font-extralight uppercase tracking-tight-5 leading-none text-center md:text-left mb-7 md:mb-11">
            {data.home_primary_heading}
          </h2>
        </Fade>
      )}
      <div className="grid grid-cols-12 gap-x-gs">
        <div className="col-span-12 md:col-span-6 mb-16 md:mb-0">
          <div className="md:max-w-md mb-6 md:mb-0">
            {data.home_primary_paragraph_one && (
              <Fade fraction={0.5} triggerOnce>
                <h2 className="text-md md:text-lg lg:text-lg-alt font-light text-black tracking-tight-5 leading-snug uppercase text-center md:text-left">
                  {data.home_primary_paragraph_one}
                  {/* Fleet, Rental, Auto Finance, <br /> Repo & Dealer Groups */}
                </h2>
              </Fade>
            )}
          </div>
          <div className="md:hidden max-w-xs mx-auto px-4">
            <VectorFeaturesCompactA />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 mb-16 md:mb-0">
          <div className="md:max-w-xl mb-6 md:mb-0">
            <Fade fraction={0.5} triggerOnce>
              {data.home_primary_paragraph_two && (
                <h2 className="text-md md:text-lg lg:text-lg-alt font-light text-blacl tracking-tight-5 leading-snug text-center md:text-left">
                  {data.home_primary_paragraph_two}
                  {/*
                  <span className="block md:inline">
                    The Auction is no longer the only way.{" "}
                  </span>
                  <span className="block md:inline">
                    You have a good process, let's make it better.
                  </span>
                  */}
                </h2>
              )}
            </Fade>
          </div>
          <div className="md:hidden max-w-xs mx-auto px-4">
            <VectorFeaturesCompactB />
          </div>
        </div>
        <div className="hidden md:block col-span-12">
          <div className="py-32 max-w-7xl mx-auto">
            <Fade fraction={0.5} triggerOnce>
              <VectorFeatures />
            </Fade>
          </div>
        </div>
        <Fade className="col-span-12" fraction={0.5} triggerOnce>
          <div>
            {data.home_secondary_heading && (
              <h3 className="text-md md:text-lg lg:text-xl xl:text-2xl text-green font-light uppercase tracking-tight-5 leading-snug text-center mb-7 md:mb-16">
                {data.home_secondary_heading}
              </h3>
            )}
            {data.home_secondary_items.length > 0 && (
              <div className="text-md md:text-lg lg:text-xl xl:text-2xl text-blue font-light tracking-tight-5 leading-snug max-w-sm md:max-w-2xl mx-auto space-y-4 md:space-y-8">
                {data.home_secondary_items
                  .filter(item => item.home_secondary_item)
                  .map((item, index) => {
                    return (
                      <div
                        className="flex items-center space-x-4 md:space-x-8"
                        key={index}
                      >
                        <span className="w-4 h-4 md:w-6 md:h-6">
                          <VectorPlus />
                        </span>
                        <span>{item.home_secondary_item}</span>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Features
