import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"
import animationData from "../json/lottie-stars.json"

const Stars = () => {
  const lottieRef = useRef(null)
  const componentRef = useRef(null)

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData,
    })

    const observer = new IntersectionObserver(
      entry => {
        const target = entry[0]
        if (target.isIntersecting) {
          lottieAnimation.play()
          observer.unobserve(componentRef.current)
        }
      },
      {
        threshold: [0.5],
      }
    )

    observer.observe(componentRef.current)
    return () => {
      lottieAnimation.destroy()
      observer.disconnect()
    }
  }, [])

  return (
    <div
      className="flex items-center px-ogs grid grid-cols-12"
      ref={componentRef}
    >
      <div
        className="col-start-1 col-span-12 lg:col-start-2 lg:col-span-10 w-full"
        ref={lottieRef}
      ></div>
    </div>
  )
}

export default Stars
