import React from "react"
import { Fade } from "react-awesome-reveal"

import ButtonPrimary from "./button/buttonPrimary"
import VectorAbout from "./svg/about"

const About = ({ data }) => {
  return (
    <Fade fraction={0.5} triggerOnce>
      <div className="grid grid-cols-12 gap-x-gs gap-y-10 px-ogs ss">
        <div className="col-span-12 md:col-span-6 lg:col-span-5 space-y-10 md:space-y-28">
          {data.home_about_heading && (
            <h3 className="text-xl lg:text-3xl font-extralight leading-none tracking-tight-3">
              {data.home_about_heading}
            </h3>
          )}
          <div className="md:-ml-ogs">
            <VectorAbout />
          </div>
        </div>
        <div className="col-span-12 md:col-start-8 lg:col-start-8 md:col-span-5 lg:col-span-4 flex flex-col justify-between md:pb-10 space-y-10">
          {data.home_about_paragraph && (
            <p className="font-light">{data.home_about_paragraph}</p>
          )}
          {data.home_about_button_link.url && (
            <ButtonPrimary
              component="a"
              href={data.home_about_button_link.url}
              full
              arrow
              larger
              label="Advent Resources"
            >
              {data.home_about_button_text}
            </ButtonPrimary>
          )}
        </div>
      </div>
    </Fade>
  )
}

export default About
