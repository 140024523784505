import React from "react"
import { graphql } from "gatsby"
import { Element } from "react-scroll"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Stars from "../components/stars"
import Features from "../components/features"
import ProductHeading from "../components/product-heading"
import ProductRetail from "../components/product-retail"
import ProductWholesale from "../components/product-wholesale"
// import Download from "../components/download"
import Partners from "../components/partners"
import Customers from "../components/customers"
import About from "../components/about"
import Contact from "../components/contact"

const IndexPage = ({ data }) => {
  const seoData = data.seo.data
  const heroData = data.hero.data
  const primaryData = data.primary.data
  const secondaryData = data.secondary.data
  const tertiaryData = data.tertiary.data
  const tertiaryPhoneData = data.tertiaryPhone.data
  const tertiaryTabletData = data.tertiaryTablet.data
  const aboutData = data.about.data
  const formData = data.form.data
  const partnersData = data.partners.data
  const customersData = data.customers.data

  return (
    <Layout isIndexPage>
      <Seo title={seoData.meta_title} description={seoData.meta_description} />
      <Hero data={heroData} />
      <Stars />
      <Features data={{ ...primaryData, ...secondaryData }} />
      <Element name="product">
        <ProductHeading data={tertiaryData} />
        <ProductRetail data={tertiaryPhoneData} />
        <ProductWholesale data={tertiaryTabletData} />
      </Element>
      {/*<Download />*/}
      <Partners data={partnersData} />
      <Customers data={customersData} />
      <Element name="about" id="about">
        <About data={aboutData} />
      </Element>
      <Element name="contact" id="contact">
        <Contact data={formData} />
      </Element>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    seo: prismicHome {
      data {
        meta_description
        meta_title
      }
    }
    hero: prismicHome {
      data {
        home_hero_heading
        home_hero_paragraph
      }
    }
    primary: prismicHome {
      data {
        home_primary_heading
        home_primary_paragraph_one
        home_primary_paragraph_two
      }
    }
    secondary: prismicHome {
      data {
        home_secondary_heading
        home_secondary_items {
          home_secondary_item
        }
      }
    }
    tertiary: prismicHome {
      data {
        home_tertiary_heading
      }
    }
    tertiaryPhone: prismicHome {
      data {
        home_tertiary_phone_heading
        home_tertiary_phone_paragraph
      }
    }
    tertiaryTablet: prismicHome {
      data {
        home_tertiary_tablet_heading
        home_tertiary_tablet_paragraph
        home_tertiary_tablet_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    about: prismicHome {
      data {
        home_about_heading
        home_about_paragraph
        home_about_button_text
        home_about_button_link {
          url
        }
      }
    }
    form: prismicHome {
      data {
        home_form_heading
        home_form_sub_heading
      }
    }
    partners: prismicPartners {
      data {
        heading
        partners {
          partner_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    customers: prismicCustomers {
      data {
        heading
        customers {
          customer_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
