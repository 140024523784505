import React from "react"
import { Fade } from "react-awesome-reveal"

const ProductHeading = ({ data }) => {
  if (!data.home_tertiary_heading) {
    return false
  }
  return (
    <div className="px-ogs ss-top-small ss-bottom-large">
      <Fade fraction={0.5} triggerOnce>
        <h2 className="text-xl lg:text-3xl xl:text-4xl 2xl:text-6xl text-center text-green font-extralight uppercase tracking-tight-5 leading-none">
          {data.home_tertiary_heading}
          {/*<span className="block md:inline">What Remarketing+ </span>
          <span className="block md:inline">can do for you.</span>*/}
        </h2>
      </Fade>
    </div>
  )
}

export default ProductHeading
