import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

const Partners = ({ data }) => {
  if (!data.partners.length) {
    return false
  } else {
    return (
      <div className="px-ogs">
        <div className="h-px bg-grey px-ogs"></div>
        <div className="grid grid-cols-12 gap-x-gs gap-y-10 md:gap-y-20 ss">
          <div className="col-span-12 md:col-span-4">
            {data.heading && (
              <Fade className="max-w-xs" fraction={0.5} triggerOnce>
                <h3 className="text-xl lg:text-3xl font-extralight leading-none tracking-tight-3">
                  {data.heading}
                </h3>
              </Fade>
            )}
          </div>
          <div className="col-span-12 md:col-span-8">
            <div className="grid grid-cols-12 lg:grid-cols-10 gap-gs md:gap-10 xl:gap-12">
              {data.partners
                .filter(item => item.partner_image.localFile)
                .map((item, index) => {
                  return (
                    <div className="col-span-4 lg:col-span-2" key={index}>
                      <Fade
                        delay={100 * (index + 1)}
                        fraction={0.5}
                        triggerOnce
                      >
                        <div
                          className="relative"
                          style={{ paddingTop: "66.666666%" }}
                        >
                          <div className="absolute inset-0">
                            <GatsbyImage
                              className="w-full h-full"
                              imgClassName="w-full h-full"
                              objectFit="contain"
                              image={getImage(item.partner_image.localFile)}
                              alt={item.partner_image.alt ?? "Partner Logo"}
                            />
                          </div>
                        </div>
                      </Fade>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className="h-px bg-grey px-ogs"></div>
      </div>
    )
  }
}

export default Partners
