import React from "react"
import axios from "axios"
import { withFormik, Form, Field } from "formik"
import ButtonPrimary from "./button/buttonPrimary"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const formName = "Contact"

const ContactFormSuccess = () => {
  return (
    <h3 className="text-lg text-center text-green font-light">
      Thank you. <br />
      Expect us to reach out soon.
    </h3>
  )
}

const ContactForm = ({ isSubmitting, errors, values }) => {
  const inputClassList =
    "block w-full h-13 bg-green bg-opacity-10 border-b border-green shadow-sm outline-none focus:shadow-sm-on transition transition-placeholder placeholder-black rounded-none"
  return (
    <Form name={formName} data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value={formName} />
      <div className="grid grid-cols-2 gap-x-gs gap-y-6 md:gap-y-9 mb-6 md:mb-16 lg:mb-20">
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-first-name">
            <span className="sr-only">First Name:</span>
            <Field
              id="contact-first-name"
              className={inputClassList}
              type="text"
              name="first-name"
              placeholder="First Name:"
              required
            />
          </label>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-last-name">
            <span className="sr-only">Last Name:</span>
            <Field
              id="contact-last-name"
              className={inputClassList}
              type="text"
              name="last-name"
              placeholder="Last Name:"
              required
            />
          </label>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-email">
            <span className="sr-only">Email:</span>
            <Field
              id="contact-email"
              className={inputClassList}
              type="email"
              name="email"
              placeholder="Email:"
              required
            />
          </label>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-phone">
            <span className="sr-only">Phone:</span>
            <Field
              id="contact-phone"
              className={inputClassList}
              type="tel"
              name="phone"
              placeholder="Phone number:"
              required
            />
          </label>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-company">
            <span className="sr-only">Company:</span>
            <Field
              id="contact-company"
              className={inputClassList}
              type="text"
              name="company"
              placeholder="Company:"
              required
            ></Field>
          </label>
        </div>
        {/*<div className="col-span-2 sm:col-span-1">
          <label htmlFor="contact-newsletter" className="ContactForm-checkbox">
            <span className="ContactForm-checkbox__label">Newsletter:</span>
            <Field
              id="contact-newsletter"
              className="ContactForm-checkbox__input"
              type="checkbox"
              name="newsletter"
            ></Field>
            <span className="ContactForm-checkbox__indicator">
              <span className="ContactForm-checkbox__indicator-el"></span>
            </span>
          </label>
        </div>*/}
        {errors.submit && (
          <div className="col-span-2">
            <p className="text-green">
              There was an error submitting the form, please try again.
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <div className="w-full sm:w-1/2">
          <ButtonPrimary
            component="submit"
            isSubmitting={isSubmitting}
            full
            large
            label="Register"
          >
            Register
          </ButtonPrimary>
        </div>
      </div>
    </Form>
  )
}

const ContactFormRender = props => {
  const { status } = props
  if (status && status.success) {
    return <ContactFormSuccess />
  } else {
    return <ContactForm {...props} />
  }
}

const ContactFormFormik = withFormik({
  mapPropsToValues: values => ({
    firstName: values.firstName || "",
    lastName: values.lastName || "",
    email: values.email || "",
    phone: values.phone || "",
    company: values.message || "",
    newsletter: values.newsletter || "",
  }),
  validate: values => {
    const errors = {}
    return errors
  },
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(
        "?no-cache=1",
        encode({ "form-name": formName, ...form }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      resetForm()
      setStatus({ success: true })
    } catch (error) {
      setErrors({ submit: true })
      if (error.response) {
        console.error(error.response)
      } else {
        console.error(error)
      }
    } finally {
      setSubmitting(false)
    }
  },
})(ContactFormRender)

export default ContactFormFormik
