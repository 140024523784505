import React, { useCallback, useState, useEffect } from "react"
import { Fade } from "react-awesome-reveal"
import { CSSTransition } from "react-transition-group"
import Portal from "./portal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ButtonPrimary from "./button/buttonPrimary"

const MASK = "mask"
const TABLET_WIDTH = 2684
const TABLET_HEIGHT = 1884

const Tablet = ({ tabletSize, children, image }) => {
  if (!image.localFile) return false

  return (
    <div
      style={tabletSize}
      className="relative bg-grey-alt rounded-2xl lg:rounded-3xl p-2 md:p-3 lg:p-4 xl:p-5 transform-gpu shadow-tablet"
    >
      <GatsbyImage
        className="rounded-2xl transform-gpu w-full h-full relative z-10"
        image={getImage(image.localFile)}
        alt={image.alt ?? "Tablet"}
      />
      {children}
    </div>
  )
}

const ProductWholesale = ({ data }) => {
  const [showModal, setShowModal] = useState(false)
  const [tabletSize, setTabletSize] = useState({
    width: "auto",
    height: "auto",
  })

  const openModal = useCallback(() => {
    const { innerWidth, innerHeight } = window
    const isLandscape = innerWidth > innerHeight
    const ratio = TABLET_WIDTH / TABLET_HEIGHT

    let width
    let height

    if (isLandscape) {
      height = Math.round(innerHeight)
      width = Math.round(height * ratio)
    }

    if (!isLandscape) {
      width = Math.round(innerWidth)
      height = Math.round(width / ratio)
    }

    document.body.classList.add("overflow-hidden")
    setTabletSize({ width: width * 0.8, height: height * 0.8 })
    setShowModal(true)
  }, [])

  const closeModal = useCallback(event => {
    if (event.target.classList.contains(MASK)) {
      setShowModal(false)
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  const handleKeyDown = useCallback(event => {
    if (event.keyCode === 27) {
      setShowModal(false)
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <>
      <div className="px-ogs xl:px-ogsl ss-bottom-large">
        <Portal>
          <CSSTransition
            in={showModal}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div
              className={`${MASK} fixed inset-0 bg-black bg-opacity-50 cursor-default z-9999`}
              onClick={closeModal}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex="0"
            >
              <div className={`${MASK} w-full h-full grid place-items-center`}>
                <Tablet
                  tabletSize={tabletSize}
                  image={data.home_tertiary_tablet_image}
                />
              </div>
            </div>
          </CSSTransition>
        </Portal>
        <Fade fraction={0.5} triggerOnce>
          <div className="grid grid-cols-12 gap-x-gs relative pb-12 md:pb-0">
            <div className="col-span-12 col-start-1 md:col-span-11 md:col-start-2 md:border md:border-green rounded-4xl">
              <div className="grid grid-cols-12 md:grid-cols-11 gap-x-gs gap-y-10">
                <div className="row-start-2 md:row-start-1 col-span-12 md:col-span-5 xl:col-span-6 md:pt-10 md:pb-14 lg:pt-16 lg:pb-24 xl:pb-32">
                  <div className="md:-ml-x md:-mr-2x-gs">
                    <Tablet
                      tabletSize={{ width: "auto", height: "auto" }}
                      image={data.home_tertiary_tablet_image}
                    >
                      <div className="hidden md:flex absolute inset-x-0 -bottom-8 lg:-bottom-12 xl:-bottom-16 justify-center z-20">
                        <ButtonPrimary
                          type="round"
                          component="button"
                          onClick={openModal}
                          icon
                          label="Open wholesale product image"
                        ></ButtonPrimary>
                      </div>
                    </Tablet>
                  </div>
                </div>
                <div className="col-start-1 col-span-12 md:col-span-6 xl:col-span-5 self-center">
                  <div className="md:px-2x-gs">
                    <div className="md:max-w-xs md:mx-auto">
                      {data.home_tertiary_tablet_heading && (
                        <h3 className="text-xl lg:text-3xl font-extralight leading-none tracking-tight-3 text-green uppercase mb-7">
                          {data.home_tertiary_tablet_heading}
                        </h3>
                      )}
                      {data.home_tertiary_tablet_paragraph && (
                        <p className="leading-relaxed">
                          {data.home_tertiary_tablet_paragraph}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ProductWholesale
