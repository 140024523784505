import React from "react"
import { Fade } from "react-awesome-reveal"
import retailVideo from "../video/retail.mp4"

const ProductRetail = ({ data }) => {
  return (
    <div className="px-ogs xl:px-ogsl ss-bottom-large">
      <div className="pb-12 md:pt-20 md:pb-32 lg:pb-36 xl:pb-48">
        <Fade
          className="md:border md:border-blue md:rounded-4xl"
          fraction={0.5}
          triggerOnce
        >
          <div className="grid grid-cols-12 gap-x-gs gap-y-10">
            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-4 lg:col-span-3 lg:col-start-3 self-center">
              <div className="md:-ml-gs">
                {data.home_tertiary_phone_heading && (
                  <h3 className="text-xl lg:text-3xl font-extralight leading-none tracking-tight-3 text-blue uppercase mb-7">
                    {data.home_tertiary_phone_heading}
                  </h3>
                )}
                {data.home_tertiary_phone_paragraph && (
                  <p className="leading-relaxed">
                    {data.home_tertiary_phone_paragraph}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-5 lg:col-start-7 relative">
              <div className="w-4/5 sm:w-3/5 md:w-2/3 md:-mt-20 md:-mb-20 xl:-mb-36 relative mx-auto">
                <div className="bg-video rounded-4xl transform-gpu shadow-tablet overflow-hidden">
                  <div className="-my-1">
                    <video muted loop autoPlay playsInline>
                      <source src={retailVideo} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default ProductRetail
