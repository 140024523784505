import React from "react"

const FeaturesCompactA = () => {
  return (
    <svg viewBox="0 0 286 161" fill="none">
      <g clipPath="url(#clip0_1423_4499)">
        <path
          d="M142.6 0.0375977C94.6 0.0375977 51.6 2.9376 17.4 14.8376C2.39995 20.0376 -4.50005 37.4376 3.09995 51.4376L32.2 105.238C32.2 105.238 98.1 105.238 142.6 105.238C187.1 105.238 253 105.238 253 105.238L282.1 51.4376C289.7 37.4376 282.9 20.0376 267.8 14.8376C233.7 2.9376 190.6 0.0375977 142.6 0.0375977Z"
          fill="url(#paint0_linear_1423_4499)"
        />
        <path
          d="M142.6 0.0375977H133.8L137.1 1.9376C138.8 2.9376 140.1 4.6376 140.4 6.5376L141.1 9.9376H142.6H144.1L144.8 6.5376C145.2 4.5376 146.4 2.8376 148.1 1.9376L151.4 0.0375977L142.6 0.0375977Z"
          fill="#6295D2"
        />
        <path
          d="M167 24.8375C151.7 25.6375 135.4 25.5375 118.4 24.7375C115.3 24.6375 112.8 22.0375 112.8 18.8375V15.1375C112.8 12.0375 115.2 9.43755 118.3 9.23755C134.5 8.23755 150.8 8.23755 167 9.23755C170.1 9.43755 172.5 12.0375 172.5 15.1375V19.0375C172.5 22.1375 170.1 24.7375 167 24.8375Z"
          fill="url(#paint1_linear_1423_4499)"
        />
        <path
          d="M138.9 64.9375V105.338"
          stroke="url(#paint2_linear_1423_4499)"
          strokeWidth="4.169"
          strokeMiterlimit="10"
        />
        <path
          d="M202.2 64.9375V105.338"
          stroke="url(#paint3_linear_1423_4499)"
          strokeWidth="4.169"
          strokeMiterlimit="10"
        />
        <path
          d="M176.7 66.5375H101.5C100.5 66.5375 99.5 66.2375 98.7 65.6375L86 56.4375C83.4 54.5375 83.4 50.7375 86 48.8375L98.7 39.6375C99.5 39.0375 100.5 38.7375 101.5 38.7375H176.7C179.3 38.7375 181.4 40.8375 181.4 43.4375V61.9375C181.4 64.5375 179.3 66.5375 176.7 66.5375Z"
          fill="url(#paint4_linear_1423_4499)"
        />
        <path
          d="M202.2 69.2376C211.368 69.2376 218.8 61.8055 218.8 52.6376C218.8 43.4697 211.368 36.0376 202.2 36.0376C193.032 36.0376 185.6 43.4697 185.6 52.6376C185.6 61.8055 193.032 69.2376 202.2 69.2376Z"
          fill="#6295D2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210.715 42.1979C208.384 40.2889 205.415 39.1376 202.2 39.1376C199.137 39.1376 196.297 40.1826 194.02 41.9324L202.5 50.4127L210.715 42.1979ZM212.818 44.3448L204.625 52.5376L212.905 60.8178C214.655 58.5407 215.7 55.7005 215.7 52.6376C215.7 49.524 214.62 46.6406 212.818 44.3448ZM210.824 62.9867L202.5 54.6625L193.907 63.2554C196.203 65.0577 199.087 66.1376 202.2 66.1376C205.465 66.1376 208.478 64.9498 210.824 62.9867ZM191.76 61.1525L200.375 52.5376L191.851 44.0133C189.888 46.36 188.7 49.3722 188.7 52.6376C188.7 55.8524 189.851 58.8219 191.76 61.1525ZM185.6 52.6376C185.6 43.4376 193 36.0376 202.2 36.0376C211.4 36.0376 218.8 43.4376 218.8 52.6376C218.8 61.8376 211.4 69.2376 202.2 69.2376C193 69.2376 185.6 61.8376 185.6 52.6376Z"
          fill="url(#paint5_linear_1423_4499)"
        />
        <path
          d="M118.8 53.3376C118.8 54.2376 118.6 55.0376 118.3 55.6376C118 56.2376 117.5 56.7376 116.9 57.1376C116.3 57.4376 115.6 57.6376 114.9 57.6376C114.1 57.6376 113.5 57.4376 112.9 57.1376C112.3 56.8376 111.8 56.3376 111.5 55.6376C111.2 54.9376 111 54.2376 111 53.3376C111 52.4376 111.2 51.6376 111.5 51.0376C111.8 50.4376 112.3 49.9376 112.9 49.5376C113.5 49.2376 114.2 49.0376 114.9 49.0376C115.6 49.0376 116.3 49.2376 116.9 49.5376C117.5 49.8376 118 50.3376 118.3 51.0376C118.7 51.6376 118.8 52.4376 118.8 53.3376ZM117 53.3376C117 52.7376 116.9 52.2376 116.7 51.8376C116.5 51.4376 116.3 51.1376 116 50.9376C115.7 50.7376 115.3 50.6376 114.9 50.6376C114.5 50.6376 114.1 50.7376 113.8 50.9376C113.5 51.1376 113.2 51.4376 113.1 51.8376C113 52.2376 112.8 52.7376 112.8 53.3376C112.8 53.9376 112.9 54.4376 113.1 54.8376C113.3 55.2376 113.5 55.5376 113.8 55.7376C114.1 55.9376 114.5 56.0376 114.9 56.0376C115.3 56.0376 115.7 55.9376 116 55.7376C116.3 55.5376 116.6 55.2376 116.7 54.8376C116.9 54.4376 117 53.9376 117 53.3376Z"
          fill="white"
        />
        <path
          d="M127.2 49.1377V57.5377H125.7L122 52.2377H121.9V57.5377H120.1V49.1377H121.7L125.3 54.4377H125.4V49.1377H127.2Z"
          fill="white"
        />
        <path
          d="M128.7 57.5377V49.1377H134.4V50.6377H130.5V52.6377H134.1V54.0377H130.5V56.0377H134.4V57.5377H128.7Z"
          fill="white"
        />
        <path
          d="M140.4 57.5377L138 49.1377H139.9L141.3 55.0377H141.4L142.9 49.1377H144.6L146.1 55.0377H146.2L147.6 49.1377H149.5L147.1 57.5377H145.4L143.8 52.0377H143.7L142.1 57.5377H140.4Z"
          fill="white"
        />
        <path
          d="M151.1 57.5377H149.2L152.1 49.1377H154.4L157.3 57.5377H155.4L153.3 51.0377H153.2L151.1 57.5377ZM151 54.2377H155.5V55.6377H151V54.2377Z"
          fill="white"
        />
        <path
          d="M157 49.1377H159L160.9 52.7377H161L162.9 49.1377H164.9L161.8 54.6377V57.6377H160V54.6377L157 49.1377Z"
          fill="white"
        />
        <path
          d="M156.8 104.537C156.8 104.537 176.8 98.6374 215.1 96.3374"
          stroke="url(#paint6_linear_1423_4499)"
          strokeWidth="1.0422"
          strokeMiterlimit="10"
        />
        <path
          d="M215.7 97.0375H208.4C207.7 97.0375 207.1 96.4375 207.1 95.7375C207.1 95.0375 207.7 94.4375 208.4 94.4375H215.7C216.4 94.4375 217 95.0375 217 95.7375C217 96.4375 216.4 97.0375 215.7 97.0375Z"
          fill="url(#paint7_linear_1423_4499)"
        />
        <path
          d="M182.5 98.7374V98.3374C182.4 97.4374 183.1 96.6374 184 96.5374C190.8 96.0374 217.7 94.2374 238.3 95.9374C239.2 96.0374 239.9 96.8374 239.8 97.7374V98.1374C216.1 95.9374 182.9 98.7374 182.5 98.7374Z"
          fill="url(#paint8_linear_1423_4499)"
        />
        <path
          d="M61.3 110.737C61.3 110.737 84.7 102.337 130.2 95.1375"
          stroke="url(#paint9_linear_1423_4499)"
          strokeWidth="1.1436"
          strokeMiterlimit="10"
        />
        <path
          d="M131.3 95.7376L122 96.7376C121.3 96.8376 120.7 96.3376 120.6 95.6376C120.5 94.9376 121 94.3376 121.7 94.2376L131 93.2376C131.7 93.1376 132.3 93.6376 132.4 94.3376C132.5 95.0376 132 95.7376 131.3 95.7376Z"
          fill="url(#paint10_linear_1423_4499)"
        />
        <path
          d="M91.5 101.737L91.4 101.338C91.2 100.438 91.9 99.5375 93 99.4375C101.1 98.0375 133.1 92.8375 157.9 91.9375C159 91.9375 159.9 92.6375 159.9 93.5375V93.9375C131.4 94.7375 91.9 101.737 91.5 101.737Z"
          fill="url(#paint11_linear_1423_4499)"
        />
        <path
          d="M261.6 117.538C261.6 109.238 254.9 102.538 246.6 102.538H97.5C90.2 102.538 83.1 100.238 77.1 96.0376C70.1 91.2376 61.1 88.3376 51.3 88.3376C30.7 88.3376 13.8 101.038 11 117.438H261.6V117.538Z"
          fill="#6295D2"
        />
        <path
          d="M23.8 119.138C23.8 104.638 35.8 92.8376 50.5 92.8376C65.2 92.8376 77.2 104.638 77.2 119.138"
          stroke="white"
          strokeWidth="2.6056"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M256 141.538H242.922V128.038H238.175V141.538H225V146.538H238.175V160.038H242.922V146.538H256V141.538Z"
        fill="url(#paint12_linear_1423_4499)"
      />
      <path
        d="M194 131.788H187.672V125.038H185.375V131.788H179V134.288H185.375V141.038H187.672V134.288H194V131.788Z"
        fill="#6295D2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1423_4499"
          x1="74"
          y1="90.0376"
          x2="316"
          y2="-67.9624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1423_4499"
          x1="122.008"
          y1="25.3899"
          x2="128.826"
          y2="-0.172899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.969502" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1423_4499"
          x1="139.4"
          y1="64.9375"
          x2="139.4"
          y2="105.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#A5DBE5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1423_4499"
          x1="202.7"
          y1="64.9375"
          x2="202.7"
          y2="105.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#A5DBE5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1423_4499"
          x1="84.0479"
          y1="52.631"
          x2="181.408"
          y2="52.631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="1" stopColor="#6295D2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1423_4499"
          x1="185.599"
          y1="52.6298"
          x2="218.803"
          y2="52.6298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.9942" stopColor="#8CE3D9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1423_4499"
          x1="165.792"
          y1="104.537"
          x2="167.521"
          y2="91.4828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.969502" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1423_4499"
          x1="207.13"
          y1="95.7277"
          x2="216.977"
          y2="95.7277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1423_4499"
          x1="191.331"
          y1="98.7374"
          x2="191.659"
          y2="93.0622"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.969502" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1423_4499"
          x1="60.631"
          y1="106.703"
          x2="130.65"
          y2="99.2534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1423_4499"
          x1="120.631"
          y1="95.6347"
          x2="132.42"
          y2="94.3804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1423_4499"
          x1="91.3645"
          y1="96.8352"
          x2="159.906"
          y2="96.8352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.9942" stopColor="#8CE3D9" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1423_4499"
          x1="229.781"
          y1="160.038"
          x2="255.691"
          y2="133.395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="0.969502" stopColor="#A5DBE5" />
        </linearGradient>
        <clipPath id="clip0_1423_4499">
          <rect
            width="285.3"
            height="120.4"
            fill="white"
            transform="translate(0 0.0375977)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FeaturesCompactA
