import React from "react"

const Plus = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 21 22">
      <path d="M21 9.28154L12.1477 9.28154L12.1477 1.13346e-06L8.93991 8.53022e-07L8.93991 9.28154L-7.95465e-07 9.28154L-1.09593e-06 12.7185L8.93991 12.7185L8.9399 22L12.1477 22L12.1477 12.7185L21 12.7185L21 9.28154Z" />
    </svg>
  )
}

export default Plus
