import { useEffect, useMemo } from "react"
import { createPortal } from "react-dom"

const Portal = ({ children }) => {
  const hasDocument = typeof document !== `undefined`

  let mount = hasDocument ? document.getElementById("portal") : null
  let el = useMemo(() => (hasDocument ? document.createElement("div") : null), [
    hasDocument,
  ])

  useEffect(() => {
    mount.appendChild(el)
    return () => mount.removeChild(el)
  }, [el, mount])

  if (el) {
    return createPortal(children, el)
  } else {
    return null
  }
}

export default Portal
