import React from "react"

const About = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 618 259" fill="none">
      <path
        d="M295.335 26.9999C203.61 26.9999 178.932 129.894 178.932 129.894C137.929 107.622 106.038 154.708 110.67 186.719C57.2147 174.948 41.2692 220.424 41.2692 220.424C3.15176 220.424 2.92396 246 -50 246H279.541V223.981H432.542C432.466 224.066 448.639 26.9999 295.335 26.9999Z"
        fill="url(#paint0_linear_1419_4497)"
      />
      <path
        d="M320.642 210.916V193.26H311.559V210.916H292.969V193.26H279.387V187.572H276.925V184.686H271.408V182.14H266.654V184.686H265.211V187.572H264.192V210.916H259.863V183.498L258.081 174.16H257.571L256.807 167.709H254.685L253.921 174.16H253.412L251.629 183.498V210.916H249.168V193.26H246.196V184.686H238.642V166.266H221.155V210.916H218.948V151.835H215.468V144.365H213.091V141.818H210.205V144.365H204.942V151.835H201.461V185.535H199.594V210.916H195.859V175.773H178.372V243.597H178.627H195.859H221.155H233.039H238.642H249.168H264.192H276.925H279.387H292.969H311.559H320.642H345.769V210.916H320.642Z"
        fill="url(#paint1_linear_1419_4497)"
      />
      <path
        d="M329.385 142.073L320.387 131.632L311.389 142.158C310.286 143.431 310.286 145.469 311.389 146.742C314.785 150.732 316.567 156.164 316.397 161.767L313.766 213.633C313.426 220.678 312.747 227.724 311.814 234.684L310.54 243.682H330.149L328.706 231.459C328.112 226.62 327.687 221.782 327.433 216.858L324.292 161.767C324.037 156.164 325.905 150.732 329.3 146.742C330.489 145.469 330.489 143.346 329.385 142.073Z"
        fill="url(#paint2_linear_1419_4497)"
      />
      <path
        d="M371.659 136.301L360.708 129.255C355.106 125.69 348.57 123.738 341.948 123.738H335.497H332.017C332.017 123.738 335.752 122.21 338.129 121.191L344.071 115.249H340.421C340.421 115.249 344.156 113.721 346.532 112.702L352.474 106.76H348.909C348.909 106.76 352.644 105.232 355.021 104.214L361.133 98.1018L348.4 100.903C341.864 102.346 335.922 105.572 331.253 110.325L319.963 123.738L309.522 110.325C304.853 105.657 298.826 102.346 292.375 100.903L279.642 98.1018L285.838 104.299C288.215 105.317 291.696 106.76 291.696 106.76H288.3L294.327 112.787C296.704 113.806 300.184 115.249 300.184 115.249H296.789L302.816 121.276C305.193 122.295 308.673 123.738 308.673 123.738H305.277H298.826C292.205 123.738 285.669 125.69 280.066 129.255L269.116 136.301H277.859C280.236 135.367 283.801 133.924 283.801 133.924L281.424 136.301H289.913C292.29 135.367 295.855 133.924 295.855 133.924L293.478 136.301H301.967C304.344 135.367 307.739 133.924 307.824 133.924L300.863 140.885C296.195 145.553 292.884 151.58 291.441 158.032L288.64 170.765L295.006 164.398C296.025 162.021 297.553 158.371 297.553 158.371V161.852L303.495 155.91C304.513 153.533 306.041 149.883 306.041 149.883V153.363L311.983 147.421C313.002 145.044 314.53 141.394 314.53 141.394V144.874L320.557 138.847L326.329 144.62V141.394C326.329 141.394 327.688 144.62 328.706 146.997L334.818 153.108V149.883C334.818 149.883 336.176 153.108 337.195 155.485L343.307 161.597V158.371C343.307 158.371 344.665 161.597 345.683 163.974L352.474 170.765L349.673 158.032C348.23 151.496 345.004 145.553 340.251 140.885L333.29 133.924C333.29 133.924 336.77 135.367 339.232 136.301H347.721L345.344 133.924C345.344 133.924 348.824 135.367 351.286 136.301H359.775L357.398 133.924C357.398 133.924 360.878 135.367 363.34 136.301H371.659V136.301Z"
        fill="url(#paint3_linear_1419_4497)"
      />
      <path
        d="M172.6 193.43L168.186 188.252L163.771 193.43C163.262 194.024 163.262 195.043 163.771 195.722C165.469 197.674 166.318 200.39 166.233 203.107L164.96 228.743C164.79 232.223 164.451 235.703 164.026 239.099L163.432 243.513H173.109L172.43 237.486C172.175 235.109 171.921 232.732 171.836 230.27L170.308 203.022C170.223 200.221 171.072 197.589 172.769 195.637C173.194 195.127 173.194 194.109 172.6 193.43Z"
        fill="url(#paint4_linear_1419_4497)"
      />
      <path
        d="M193.482 190.628L188.049 187.148C185.248 185.365 182.022 184.432 178.796 184.432H175.571H173.873C173.873 184.432 175.74 183.668 176.844 183.158L179.73 180.272H178.032C178.032 180.272 179.9 179.508 181.003 178.999L183.89 176.113H182.192C182.192 176.113 184.059 175.349 185.163 174.839L188.134 171.868L181.852 173.227C178.627 173.906 175.656 175.519 173.364 177.895L167.846 184.517L162.668 177.895C160.376 175.603 157.405 173.991 154.179 173.227L147.898 171.868L150.954 174.924C152.142 175.434 153.84 176.113 153.84 176.113H152.142L155.113 179.084C156.301 179.593 157.999 180.272 157.999 180.272H156.301L159.272 183.243C160.461 183.753 162.159 184.432 162.159 184.432H160.461H157.235C153.925 184.432 150.699 185.365 147.983 187.148L142.55 190.628H146.879C148.067 190.119 149.765 189.44 149.765 189.44L148.577 190.628H152.736C153.925 190.119 155.622 189.44 155.622 189.44L154.434 190.628H158.593C159.782 190.119 161.48 189.44 161.48 189.44L158.084 192.835C155.792 195.127 154.179 198.098 153.415 201.324L152.057 207.606L155.198 204.465C155.707 203.276 156.471 201.494 156.471 201.494V203.192L159.357 200.305C159.867 199.117 160.631 197.334 160.631 197.334V199.032L163.517 196.146C164.026 194.958 164.79 193.175 164.79 193.175V194.873L167.761 191.902L170.562 194.703V193.09C170.562 193.09 171.241 194.703 171.751 195.891L174.807 198.947V197.334C174.807 197.334 175.486 198.947 175.995 200.136L179.051 203.192V201.579C179.051 201.579 179.73 203.192 180.239 204.38L183.635 207.775L182.277 201.494C181.598 198.268 179.985 195.297 177.608 193.005L174.128 189.525C174.128 189.525 175.825 190.204 177.014 190.713H181.173L179.985 189.525C179.985 189.525 181.683 190.204 182.871 190.713H187.03L185.842 189.525C185.842 189.525 187.54 190.204 188.728 190.713H193.482V190.628Z"
        fill="url(#paint5_linear_1419_4497)"
      />
      <path
        d="M535.83 148.27L536.169 137.404C528.614 137.744 522.163 131.972 521.823 124.417C521.569 118.305 521.993 111.174 524.54 105.402C530.142 92.4994 554.505 86.048 559.938 105.572C565.37 125.181 555.523 134.349 555.523 134.349L563.333 154.297L535.83 148.27Z"
        fill="#FFCCB6"
      />
      <path
        d="M524.54 107.524L528.36 107.864L531.501 117.286C532.18 119.239 534.726 119.578 535.83 117.88C538.122 114.4 541.178 111.174 542.196 116.692C543.979 125.86 539.31 135.028 555.439 134.433C559.089 134.264 561.89 128.576 563.927 121.615C571.143 97.0832 538.461 80.785 523.776 101.667C523.352 102.261 522.927 102.94 522.503 103.62C521.569 105.232 522.588 107.355 524.54 107.524Z"
        fill="url(#paint6_linear_1419_4497)"
      />
      <path
        d="M439.568 220.848C439.568 213.802 445.256 208.115 452.301 208.115L516.476 200.9L513.59 177.726L541.432 176.707L544.234 201.579C545.422 211.765 537.358 220.763 527.087 220.763H439.568V220.848Z"
        fill="#4B8F39"
      />
      <path
        d="M464.949 206.672V220.848H439.568C439.568 213.802 445.256 208.115 452.301 208.115L464.949 206.672Z"
        fill="#FFCCB6"
      />
      <path
        d="M559.004 142.752L534.387 143.771L529.973 151.92L565.031 151.071L561.126 143.941C560.702 143.177 559.938 142.667 559.004 142.752Z"
        fill="#4B8F39"
      />
      <path
        d="M524.285 220.848H589.478C589.818 220.848 590.157 220.848 590.412 220.848C602.126 220.169 609.342 207.521 604.333 196.91L592.619 171.868L575.896 177.895L578.782 201.069L527.426 206.842L524.285 220.848Z"
        fill="url(#paint7_linear_1419_4497)"
      />
      <path
        d="M431.759 220.678H394.069L412.914 171.783H450.604L431.759 220.678Z"
        fill="#8CCAD5"
      />
      <path
        d="M618 243.598H293.733C293.733 230.95 304.004 220.678 316.652 220.678H618V243.598Z"
        fill="url(#paint8_linear_1419_4497)"
      />
      <path
        d="M495.509 191.562H363.255C359.775 191.562 356.889 188.761 356.889 185.196V111.005C356.889 107.524 359.69 104.638 363.255 104.638H495.593C499.074 104.638 501.96 107.439 501.96 111.005V185.196C501.875 188.761 499.074 191.562 495.509 191.562Z"
        fill="url(#paint9_linear_1419_4497)"
      />
      <path
        d="M492.707 184.092H366.056C365.207 184.092 364.528 183.413 364.528 182.564V113.721C364.528 112.872 365.207 112.193 366.056 112.193H492.707C493.556 112.193 494.235 112.872 494.235 113.721V182.564C494.235 183.413 493.556 184.092 492.707 184.092Z"
        fill="white"
      />
      <path d="M485.662 178.32H373.102V121.191" fill="white" />
      <path
        d="M485.662 178.32H373.102V121.191"
        stroke="#4B8F39"
        strokeWidth="1.87226"
        strokeMiterlimit="10"
      />
      <path
        d="M580.475 243.598C595.872 243.598 607.287 229.303 603.88 214.287L596.778 182.988C592.704 164.992 578.103 151.241 559.853 148.185L531.416 149.798C518.683 153.957 510.703 166.52 512.146 179.763L519.277 243.598H580.475Z"
        fill="url(#paint10_linear_1419_4497)"
      />
      <path
        d="M532.944 23.9109L414.102 156.928L403.661 147.336L379.553 171.783"
        stroke="url(#paint11_linear_1419_4497)"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M535.83 171.783L538.122 201.069C538.971 211.595 530.652 220.508 520.126 220.508H516.73"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M512 23H534V45"
        stroke="#A5DBE5"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M81.5164 250.304H233.124C235.076 250.304 237.029 249.455 238.302 248.012C241.103 244.956 241.782 240.542 240.17 236.722C238.217 232.308 235.161 227.384 224.805 224.668C210.884 221.018 199.848 219.66 199.848 219.66C199.848 219.66 163.602 206.078 134.061 206.078C104.521 206.078 89.2411 215.67 77.1023 215.67L70.566 216.179C69.6323 216.264 68.9532 217.113 69.1229 218.047L70.0567 223.395C70.0567 223.395 64.8786 227.894 64.2844 232.393H61.7378V235.703C61.7378 239.523 64.0297 243.003 67.595 244.531L81.5164 250.304Z"
        fill="url(#paint12_linear_1419_4497)"
      />
      <path
        d="M204.857 259.386C212.311 259.386 218.354 253.344 218.354 245.889C218.354 238.435 212.311 232.392 204.857 232.392C197.402 232.392 191.36 238.435 191.36 245.889C191.36 253.344 197.402 259.386 204.857 259.386Z"
        fill="black"
      />
      <path
        d="M98.2389 259.386C105.693 259.386 111.736 253.344 111.736 245.889C111.736 238.435 105.693 232.392 98.2389 232.392C90.7847 232.392 84.7419 238.435 84.7419 245.889C84.7419 253.344 90.7847 259.386 98.2389 259.386Z"
        fill="black"
      />
      <path
        d="M103.757 220.084H184.908C184.908 220.084 159.952 208.03 135.504 208.03C119.715 208.03 102.483 211.086 100.955 215.076C99.9368 217.962 103.757 220.084 103.757 220.084Z"
        fill="url(#paint13_linear_1419_4497)"
      />
      <path
        d="M218.693 226.196C218.693 226.196 221.919 233.92 236.01 235.194C236.01 235.194 233.464 229.421 229.559 228.148C225.739 226.875 218.693 226.196 218.693 226.196Z"
        fill="white"
      />
      <path
        d="M204.857 253.19C208.888 253.19 212.157 249.921 212.157 245.889C212.157 241.858 208.888 238.589 204.857 238.589C200.825 238.589 197.556 241.858 197.556 245.889C197.556 249.921 200.825 253.19 204.857 253.19Z"
        fill="#4B8F39"
      />
      <path
        d="M98.239 253.19C102.271 253.19 105.539 249.921 105.539 245.889C105.539 241.858 102.271 238.589 98.239 238.589C94.2071 238.589 90.9387 241.858 90.9387 245.889C90.9387 249.921 94.2071 253.19 98.239 253.19Z"
        fill="#4B8F39"
      />
      <path
        d="M235.755 171.783H233.548V174.924H235.755V171.783Z"
        fill="url(#paint14_linear_1419_4497)"
      />
      <path
        d="M210.884 190.713H202.48V191.817H210.884V190.713Z"
        fill="url(#paint15_linear_1419_4497)"
      />
      <path
        d="M247.979 194.958H246.706V198.947H247.979V194.958Z"
        fill="url(#paint16_linear_1419_4497)"
      />
      <path
        d="M224.975 184.686H223.871V186.978H224.975V184.686Z"
        fill="url(#paint17_linear_1419_4497)"
      />
      <path
        d="M291.781 198.523H290.677V200.815H291.781V198.523Z"
        fill="url(#paint18_linear_1419_4497)"
      />
      <path
        d="M287.791 198.523H286.688V200.815H287.791V198.523Z"
        fill="url(#paint19_linear_1419_4497)"
      />
      <path
        d="M289.828 198.523H288.725V200.815H289.828V198.523Z"
        fill="url(#paint20_linear_1419_4497)"
      />
      <path
        d="M216.911 155.995H215.807V158.287H216.911V155.995Z"
        fill="url(#paint21_linear_1419_4497)"
      />
      <path
        d="M214.873 155.995H213.77V158.287H214.873V155.995Z"
        fill="url(#paint22_linear_1419_4497)"
      />
      <path
        d="M279.557 189.1H274.634V190.204H279.557V189.1Z"
        fill="url(#paint23_linear_1419_4497)"
      />
      <path
        d="M256.213 172.293H255.619V174.245H256.213V172.293Z"
        fill="url(#paint24_linear_1419_4497)"
      />
      <path
        d="M255.619 183.668V184.262H259.269V183.668H255.619Z"
        fill="url(#paint25_linear_1419_4497)"
      />
      <path
        d="M255.619 184.941V185.535H259.269V184.941H255.619Z"
        fill="url(#paint26_linear_1419_4497)"
      />
      <path
        d="M279.557 191.562H274.634V192.666H279.557V191.562Z"
        fill="url(#paint27_linear_1419_4497)"
      />
      <path
        d="M429 10.125H418.875V0H415.2V10.125H405V13.875H415.2V24H418.875V13.875H429V10.125Z"
        fill="url(#paint28_linear_1419_4497)"
      />
      <path
        d="M256 64.125H245.875V54H242.2V64.125H232V67.875H242.2V78H245.875V67.875H256V64.125Z"
        fill="white"
      />
      <path
        d="M294 47.5938H286.406V40H283.65V47.5938H276V50.4062H283.65V58H286.406V50.4062H294V47.5938Z"
        fill="white"
      />
      <path
        d="M274 83.0625H268.938V78H267.1V83.0625H262V84.9375H267.1V90H268.938V84.9375H274V83.0625Z"
        fill="white"
      />
      <g clipPath="url(#clip0_1419_4497)">
        <path
          d="M434 42.4L440.5 42.6"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M439.5 29.4L444.1 33.9"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M452.6 24V30.5"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M465.7 29.5L461.1 34.1"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M471 42.6H464.6"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M465.6 55.6L461 51.1"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M452.5 61L452.4 54.6"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M439.4 55.5L443.9 51"
          stroke="#4B8F39"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <circle cx="243" cy="131" r="11" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1419_4497"
          x1="-50.0759"
          y1="136.499"
          x2="432.961"
          y2="136.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.0906266" stopColor="#9DE3FF" />
          <stop offset="0.5104" stopColor="#D2F2FF" />
          <stop offset="0.826" stopColor="#F3FCFF" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1419_4497"
          x1="178.413"
          y1="192.681"
          x2="345.774"
          y2="192.681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F42C0" />
          <stop offset="1" stopColor="#7795FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1419_4497"
          x1="310.534"
          y1="187.578"
          x2="330.176"
          y2="187.578"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2032" stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#D9F7F2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1419_4497"
          x1="269.036"
          y1="134.469"
          x2="371.674"
          y2="134.469"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2032" stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#D9F7F2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1419_4497"
          x1="163.328"
          y1="215.911"
          x2="173.029"
          y2="215.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A8D36" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1419_4497"
          x1="142.834"
          y1="189.683"
          x2="193.523"
          y2="189.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0028B8" />
          <stop offset="1" stopColor="#A5DBE5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1419_4497"
          x1="521.545"
          y1="107.466"
          x2="565.044"
          y2="117.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0027B1" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1419_4497"
          x1="524.283"
          y1="196.347"
          x2="605.953"
          y2="196.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B8F39" />
          <stop offset="0.9942" stopColor="#8CE3D9" />
          <stop offset="1" stopColor="#8CE5D9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1419_4497"
          x1="293.708"
          y1="232.098"
          x2="617.975"
          y2="232.098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1419_4497"
          x1="364.284"
          y1="168.736"
          x2="502.516"
          y2="124.959"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2032" stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#D9F7F2" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1419_4497"
          x1="511.976"
          y1="195.869"
          x2="610.538"
          y2="195.869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B8F39" />
          <stop offset="0.9942" stopColor="#8CE3D9" />
          <stop offset="1" stopColor="#8CE5D9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1419_4497"
          x1="527"
          y1="38"
          x2="376.5"
          y2="179.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="1" stopColor="#4A8D36" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1419_4497"
          x1="61.7339"
          y1="228.18"
          x2="241.043"
          y2="228.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B8F39" />
          <stop offset="0.9942" stopColor="#8CE3D9" />
          <stop offset="1" stopColor="#8CE5D9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1419_4497"
          x1="100.79"
          y1="214.044"
          x2="184.878"
          y2="214.044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0028B8" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1419_4497"
          x1="233.568"
          y1="173.318"
          x2="235.748"
          y2="173.318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1419_4497"
          x1="202.511"
          y1="191.229"
          x2="210.912"
          y2="191.229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1419_4497"
          x1="246.736"
          y1="196.952"
          x2="248.01"
          y2="196.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1419_4497"
          x1="223.854"
          y1="185.816"
          x2="224.943"
          y2="185.816"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1419_4497"
          x1="290.694"
          y1="199.65"
          x2="291.783"
          y2="199.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1419_4497"
          x1="286.672"
          y1="199.65"
          x2="287.762"
          y2="199.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1419_4497"
          x1="288.762"
          y1="199.65"
          x2="289.852"
          y2="199.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1419_4497"
          x1="215.845"
          y1="157.119"
          x2="216.935"
          y2="157.119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1419_4497"
          x1="213.794"
          y1="157.119"
          x2="214.884"
          y2="157.119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1419_4497"
          x1="274.607"
          y1="189.683"
          x2="279.51"
          y2="189.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1419_4497"
          x1="255.588"
          y1="173.318"
          x2="256.223"
          y2="173.318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1419_4497"
          x1="257.447"
          y1="183.656"
          x2="257.447"
          y2="184.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1419_4497"
          x1="257.447"
          y1="184.939"
          x2="257.447"
          y2="185.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1419_4497"
          x1="274.607"
          y1="192.114"
          x2="279.51"
          y2="192.114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5DBE5" />
          <stop offset="0.999" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1419_4497"
          x1="408.701"
          y1="24"
          x2="428.107"
          y2="3.40209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0028B8" />
          <stop offset="0.969502" stopColor="#A5DBE5" />
        </linearGradient>
        <clipPath id="clip0_1419_4497">
          <rect
            width="37"
            height="37"
            fill="white"
            transform="translate(434 24)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default About
